.c-site-menu {
  position: fixed;
  z-index: $zindex-site-menu;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgb(0 0 0 / 95%);
    opacity: 0;
    transition: opacity 0.25s;

    .show-menu & {
      opacity: 1;
    }
  }

  .show-menu & {
    pointer-events: auto;
  }

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
  }
}

.c-site-menu__logo {
  position: absolute;
  z-index: 2;
  top: var(--site-menu-header-padding);
  left: var(--container-padding);
  display: flex;
  align-items: center;
  height: var(--site-menu-button-size);
  color: var(--color-white);
  opacity: 0;
  transition: opacity 0.25s;

  .show-menu & {
    opacity: 1;
  }

  .tpl-home & {
    pointer-events: none;
  }
}

.c-site-menu__toggle {
  position: absolute;
  z-index: 4;
  top: var(--site-menu-header-padding);
  right: calc(var(--container-padding) + var(--scrollbar-width, 0px));
  width: var(--site-menu-button-size);
  height: var(--site-menu-button-size);
  background-color: var(--color-light-gray);
  border: none;
  border-radius: 100%;
  appearance: none;
  pointer-events: auto;
  transition: transform 0.65s cubic-bezier('easeInOutQuart');
  will-change: transform;

  span {
    --y: calc(var(--site-menu-button-size) / 15);

    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: calc(var(--site-menu-button-size) / 2.5);
    height: 2px;
    transform-origin: 50% 50%;
    will-change: transform;
    transition: transform 0.3s cubic-bezier('easeOutQuart') 0.3s;
    transform: translate(-50%, calc(var(--y) * var(--sign, 1)));

    &:last-child {
      --sign: -1;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--color-black);
      will-change: transform;
      border-radius: 10px;
      transition: transform 0.3s cubic-bezier('easeInQuart');
      transform-origin: 50% 50%;
      transform: rotate(calc(var(--r, 0) * var(--sign, 1)));
      content: '';
    }
  }

  .show-menu &,
  .has-overlay & {
    transform: rotate(180deg);

    span {
      --y: 0%;

      transition-delay: 0.01s;
      transition-timing-function: cubic-bezier('easeInQuart');

      &::before {
        --r: -45deg;

        transition-delay: 0.3s;
        transition-timing-function: cubic-bezier('easeOutQuart');
      }
    }
  }

  .has-overlay & {
    pointer-events: none;
  }
}

.c-site-menu__wrapper {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  left: 0;
  overflow-y: scroll;
  box-sizing: content-box;
  height: calc(100 * var(--dvh));
  padding: 0 var(--container-padding);
  opacity: 0;
  -webkit-overflow-scrolling: touch;
  pointer-events: none;

  @include media-breakpoint-up(sm) {
    left: auto;
    width: calc(var(--column-width-gutter) * 8 - var(--gutter-x));
  }

  @include media-breakpoint-up(md) {
    width: calc(var(--column-width-gutter) * 7 - var(--gutter-x));
  }

  @include media-breakpoint-up(lg) {
    width: calc(var(--column-width-gutter) * 6 - var(--gutter-x));
  }

  .show-menu & {
    opacity: 1;
    pointer-events: auto;
  }
}

.c-site-menu__inner {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: calc(100 * var(--dvh));

  > * {
    opacity: 0;
    transform: translateY(var(--space-m));
    will-change: transform;

    .show-menu & {
      opacity: 1;
      transform: none;
      transition: transform 0.6s cubic-bezier('easeOutQuart'), opacity 0.6s;
      transition-delay: calc(var(--index, 0) * 0.1s);
    }
  }
}

.c-site-menu__top,
.c-site-menu__middle-primary {
  ul {
    margin-bottom: var(--space-m);
    font-size: var(--step-3);
    font-weight: 500; // very similar as site-footer

    @include media-breakpoint-up(sm) {
      margin-bottom: 0;
    }
  }

  li {
    @extend %heading;

    &:not(:last-child) {
      margin-bottom: var(--space-2xs);
    }
  }
}

.c-site-menu__top,
.c-site-menu__middle {
  @include menu-footer-links;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--space-s);
  border-radius: 7px;
}

.c-site-menu__top {
  flex-grow: 1;
  margin-top: calc(var(--site-menu-button-size) + var(--site-menu-header-padding) * 2);
  background: var(--color-yellow);
}

.c-site-menu__middle {
  --index: 1;

  flex-grow: 1;
  margin-top: var(--space-s);
  background: var(--color-white);
}

.c-site-menu__middle-secondary {
  padding-top: var(--step-2);
  font-size: var(--step--1);
}

.c-site-menu__bottom {
  --index: 2;

  padding-bottom: var(--container-padding);
}

@keyframes toggleBefore {
  20% {
    transform: translate(-50%, -50%);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

@keyframes toggleAfter {
  20% {
    transform: translate(-50%, -50%);
  }

  100% {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

@keyframes close {
  100% {
    transform: translate(-50%, 0);
  }

  // 100% {
  //   transform: translate(-50%, -50%) rotate(45deg);
  // }
}
