.c-text-list {
  padding: var(--space-m) 0 var(--space-section-large) 0;
  background: var(--color-white);

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
  }
}

.c-text-list__heading {
  margin-bottom: var(--space-xl);
}

.c-text-list__title {
  display: inline;
  font-size: var(--step-7);
}

.c-text-list__counter {
  display: inline;
  font-size: var(--step--1);
  font-weight: 400;
  line-height: 1;
  vertical-align: baseline;
}

.c-text-list__list {
  @include media-breakpoint-up(sm) {
    --column-span: 9;
    --column-start: 4;
  }

  @include media-breakpoint-up(md) {
    --column-span: 8;
    --column-start: 5;
  }

  @include media-breakpoint-up(lg) {
    --column-span: 7;
    --column-start: 6;
  }
}

.c-text-list__item {
  position: relative;
  display: flex;
  padding: var(--space-s) 0;
  border-bottom: 1px solid var(--color-mid-blue-gray);

  @include media-breakpoint-up(sm) {
    padding: var(--space-m) 0;
  }

  .can-hover &::before {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background: #000;
    opacity: 0.75;
    transform-origin: 0 0;
    transform: scaleX(0);
    transition: transform 0.7s cubic-bezier('easeInOutQuart');
  }

  .can-hover &:hover::before {
    transform: scaleX(1);
  }

  .o-pill {
    position: relative;
    top: calc(var(--step-2) * 0.1);

    @include media-breakpoint-up(sm) {
      top: calc(var(--step-3) * 0.15);
    }
  }
}

.c-text-list__text {
  @extend %heading;

  margin-left: var(--space-s-m);
  font-size: var(--step-2);

  @include media-breakpoint-up(sm) {
    font-size: var(--step-3);
  }
}
