.o-floating-cta {
  padding-left: var(--container-padding);

  @include media-breakpoint-up(sm) {
    // calc height using button paddings
    --height: calc(var(--space-xs) * 2 + var(--step-0));

    position: sticky;
    z-index: 2;
    top: calc(100vh - var(--space-m));
    transform: translateY(calc(var(--height) * -1));
    height: 0;

    .c-work & .o-button,
    .c-post & .o-button {
      background-color: var(--color-light-gray);
    }
  }
}
