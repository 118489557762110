.c-post {
  --flow: var(--space-section);

  + * {
    --flow: var(--space-section-large);
  }

  .c-text {
    @include media-breakpoint-up(sm) {
      padding-bottom: var(--space-section-large);
    }
  }
}

.c-post__cover {
  --media-ratio: calc(9 / 16);
  --media-reveal: var(--body-bg);
  --post-cover-columns: 1;
  --flow: var(--space-s);

  margin-left: calc(var(--container-padding) + var(--column-width-gutter) * var(--post-cover-columns));

  @include media-breakpoint-up(md) {
    --post-cover-columns: 4;
  }

  @include media-breakpoint-up(sm) {
    margin-top: 0 !important;
  }

  [data-bgset] {
    background-position: top right !important;
    background-size: contain;
  }
}
