.c-work {
  --flow: var(--space-section);

  // prevent ugly positions for the floating cta
  @include media-breakpoint-up(sm) {
    .c-text-list:last-child {
      // if c-text-list is the last child, floating cta will be on a white bg
      // add a gray section to space it
      border-bottom: var(--space-section-large) solid var(--body-bg);
    }

    .c-text:last-child,
    .c-text-highlight:last-child  {
      // if c-text or c-text-highlight is a last child, floating cta will be too near
      // add a padding bottom
      padding-bottom: var(--space-section-large);
    }
  }

  + * {
    --flow: var(--space-section-large);
  }
}

.c-work__cover {
  --media-reveal: var(--body-bg);
  --flow: var(--space-s);
  --media-ratio: calc(3 / 4);

  @include media-breakpoint-up(sm) {
    --media-ratio: calc(1 / 2);

    margin-top: 0 !important;
  }
}
