.c-post-list {
  --media-reveal: var(--body-bg);

  .is-loading & {
    opacity: 0.5;
  }

  ul {
    list-style: none;
  }
}

.c-post-list__item {
  --gutter-y: 0;

  position: relative;
  padding: var(--space-s) 0;
  border-top: 1px solid var(--color-white);

  @include media-breakpoint-down(sm) {
    padding-bottom: var(--space-l);
  }

  @include media-breakpoint-up(sm) {
    min-height: calc(var(--step-3) * 8);
  }

  > *:not(a) {
    pointer-events: none;
    position: relative;
    z-index: 2;
  }

  &:last-child {
    border-bottom: 1px solid var(--color-white);
  }
}

.c-post-list__link {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @include media-breakpoint-up(sm) {
    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(#fff, 0.2);
      opacity: 0;
      will-change: opacity;
      transition: opacity 0.2s;
      content: '';
    }

    .can-hover &:hover::before,
    .can-hover &:focus::before {
      opacity: 1;
    }
  }
}

.c-post-list__title {
  --column-start: 1;
  --column-span: 12;

  text-wrap: balance;
  align-self: center;
  max-width: 20ch;
  font-size: var(--step-3);

  @include media-breakpoint-up(sm) {
    --column-start: 1;
    --column-span: 5;

    .can-hover & {
      transition: transform 0.3s;
    }

    .can-hover a:hover ~ &,
    .can-hover a:focus ~ & {
      transform: translateX(calc(var(--gutter-x) * 0.5));
    }
  }

  @include media-breakpoint-up(md) {
    --column-start: 1;
    --column-span: 6;
  }
}

.c-post-list__categories {
  --column-start: 1;
  --column-span: 12;

  position: relative;
  z-index: 2;
  display: flex;
  margin-top: var(--space-xs);
  pointer-events: none;

  @include media-breakpoint-up(sm) {
    --column-start: 6;
    --column-span: 3;

    flex-direction: column;
    justify-content: center;
    margin-top: 0;

    .c-tags {
      --tag-direction: column;
      --tag-gap: var(--space-3xs);
    }
  }

  @include media-breakpoint-up(md) {
    --column-start: 7;
    --column-span: 3;
  }

  .c-tags {
    --index: 3;
    --reveal-y: var(--space-s);
  }
}

.c-post-list__figure {
  --column-start: 1;
  --column-span: 12;

  position: relative;

  @include media-breakpoint-down(sm) {
    height: 50vw;
    margin-top: var(--space-l);
  }

  @include media-breakpoint-up(sm) {
    --column-start: 9;
    --column-span: 4;

    .can-hover & {
      transition: transform 0.3s;
    }

    .can-hover a:hover ~ &,
    .can-hover a:focus ~ & {
      transform: translateX(calc(var(--gutter-x) * -0.5));
    }
  }

  @include media-breakpoint-up(md) {
    --column-start: 10;
    --column-span: 3;
  }

  .o-media {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .o-media--bg {
    height: auto;
    padding-bottom: 0;
  }

  [data-bgset] {
    background-position: center right !important;
    background-size: contain;
  }
}

.c-post-list__cta {
  display: flex;
  justify-content: flex-end;
  padding-top: var(--space-l-2xl);
}
