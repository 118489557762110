.news-results {

  &.mode-1 {
    --color-results:  var(--color-white);
    background-color: var(--color-black);
    color: var(--color-results);
  }

  &.mode-2 {
    --color-results: var(--color-black);
    background-color: var(--color-white);
    color: var(--color-results);

  }

  .news-results__title {
    font-size: 30px;
    padding-bottom: 30px;
  }

  @include media-breakpoint-down(lg) {

    .border-container{
      border: none;
    }

    .news-results__list {
      display: flex;
      flex-direction:column;

      &.results {
        li {

          &:not(:last-child) {
            border-right: none;          
          }

          h3 {
            font-size: 64px
          }

        }
      }

      
      

    }

  }

  .news-results__list {
    display: flex;
    list-style: none;
    margin-inline: calc(-1* var(--container-padding));
    border-top: 1px solid var(--color-results);
    
    li {
      border-width: 0px 0px 1px 0px;
      border-color: var(--color-results);
      border-style: solid;
      flex: 1;
      padding: 30px;

      > :first-child::before {
        background-color: var(--color-yellow);
        border-radius: 100%;
        content: "";
        height: 22px;
        min-width: 22px;
        display: block;
     }

      h3 {
        color: var(--Color-Text-focus, #CED2D4);
        font-size: 72px;
        font-weight: 300;
      }

      h3,p {
        display: flex;
        align-items: center;
        gap: 20px;
        width: 100%;
      }

      &:not(:last-child) {
        border-right: 1px solid var(--color-results);
      }

      .news-results__item-text {
        font-size: 24px;
        font-weight: 300;
        margin-top: 20px;
        line-height: 125%;
      }
    }
  }
}