.c-image__container {
  .c-image--inside & {
    --margin: var(--container-padding);

    margin: 0 var(--margin);

    @include media-breakpoint-up(md) {
      --margin: calc(var(--container-padding) + var(--column-width-gutter));
    }
  }
}
