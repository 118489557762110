.o-gl {
  position: relative;
  height: 100%;
  background: #000;

  .gl-is-resizing & {
    canvas {
      visibility: hidden;
    }
  }

  &.is-disabled {
    display: none;
  }

  .c-page__main > & {
    height: 100vh;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    opacity: 1;
    transition: opacity 4s;
    will-change: opacity;
  }

  &.is-ready::after {
    opacity: 0.2;
  }

  canvas {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
  }
}
