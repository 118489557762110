/* stylelint-disable selector-max-id */
/* stylelint-disable selector-id-pattern */
/* stylelint-disable scss/at-extend-no-missing-placeholder */
/* stylelint-disable selector-class-pattern */
.FormBuilder {
  .alert-error {
    font-weight: 500;
  }

  .alert-success {
    min-height: 50vh;
    font-size: var(--step-3);
  }

  #FormBuilderSubmitted ~ .InputfieldMarkup .InputfieldContent {
    @extend .o-prose;

    min-height: 50vh;
  }

  button {
    @extend %button;

    // flex-direction: row-reverse;
    width: 100%;
    background-color: var(--color-yellow);

    .o-button__label {
      margin-left: auto;
    }

    // color: var(--color-white);
    // background: var(--color-black);

    // &:focus {
    //   outline-color: var(--mid-blue-gray);
    // }
  }

  .InputfieldHeader {
    text-transform: uppercase;
  }

  .InputfieldStateError {
    input {
      background-color: #{rgba(color('red'), 0.2)};
      border-color: var(--color-red) !important;
    }
  }

  .input-error {
    font-weight: 500;
  }

  .Inputfield {
    width: 100% !important;
  }
}
