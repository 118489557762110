.c-post-header {
  padding-top: calc(var(--site-header-height) + var(--space-l-xl));
  padding-bottom: var(--space-section-medium);
}

.c-post-header__title {
  font-size: var(--step-5);

  @include media-breakpoint-up(sm) {
    --column-span: 10;

    font-size: var(--step-6);
  }

  @include media-breakpoint-up(md) {
    --column-span: 8;
  }
}
