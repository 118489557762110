.o-cta-square {
  --columns: 8;
  --size: calc(var(--column-width-gutter) * var(--columns) - var(--gutter-x));
  --icon-width: calc(var(--space-m) * 0.8);
  --icon-height: calc(var(--space-m) * 0.8);
  --icon-bg: var(--icon-arrow-45);

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-self: flex-end;
  width: var(--size);
  height: var(--size);
  padding: var(--space-2xs) var(--space-xs);
  font-size: calc(var(--size) * 0.11);
  line-height: 1.1;
  text-align: right;
  text-decoration: none;
  text-transform: uppercase;
  background: var(--color-yellow);
  border-radius: var(--border-radius);
  overflow-wrap: unset;

  @include media-breakpoint-up(sm) {
    --columns: 4;
  }

  @include media-breakpoint-up(md) {
    --columns: 3;
  }

  &::before {
    @include arrow-icon(true);

    position: absolute;
    top: 3%;
    left: 3%;
  }

  .can-hover &:hover::before {
    @include arrow-icon-hover(true);
  }
}

.o-cta-square--force-lines {
  word-spacing: 99999px;
}
