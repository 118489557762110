.c-text-w-image {
  padding: var(--space-section-large) 0;
  background-color: var(--color-white);

  .c-landing-header + & {
    margin-top: 0;
  }

  .o-grid {
    align-items: center;
  }

  .o-button {
    /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
    @extend .o-button--primary;
  }
}

.c-text-w-image__body {
  @include media-breakpoint-up(sm) {
    --column-span: 6;
  }
}

.c-text-w-image__media {
  @include media-breakpoint-up(sm) {
    --column-span: 6;
  }

  @include media-breakpoint-up(md) {
    --column-span: 4;
    --column-start: 8;
  }
}
