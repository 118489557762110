.hbspt-form {
  .hs-input[type="checkbox"] {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: var(--body-color);
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    float: left;
    width: 1em;
    height: 1em;
    margin-top: .25em;
    margin-left: -1.5em;
    vertical-align: top;
    background-color: #eceeee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid transparent;
    appearance: none;
    border-radius: .25em;

    &:checked {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");
      background-color: #000000;
      border-color: #000000;
    }
  }

  .hs-input{
    &.error {
      border-radius: var(--max-space-3xs, 6px);
      border: 1px solid var(--Alert-Error, #FF5348);
      background: var(--alert-error-20, rgba(255, 83, 72, 0.30));
    }
  }

  .hs-submit {
    --icon-width: var(--space-s);
    --icon-height: var(--space-s);
    --icon-bg: var(--icon-arrow-45);

    input {
      background-color: transparent;
      border: none;
      width: 100%;
      position: absolute;
      inset: 0;
      text-align: left;
      padding-left: 55px;
      cursor: pointer;
    }

    .actions {
      box-sizing: border-box;
      margin: 0;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      user-select: none;
      cursor: pointer;
      --icon-width: var(--space-s);
      --icon-height: var(--space-s);
      --icon-bg: var(--icon-arrow-45);
      display: inline-flex;
      align-items: center;
      padding: var(--space-xs) var(--space-s);
      color: var(--color-black);
      line-height: 1;
      text-align: left;
      text-decoration: none;
      text-transform: uppercase;
      border: none;
      border-radius: var(--border-radius);
      appearance: none;
      width: 100%;
      background-color: var(--color-yellow);
      position: relative;

      &:hover {
        &::before {
          background-position: calc(var(--icon-width) * 2) calc(var(--icon-width) * 2), 0 0;
        }
      }

      &::before {
        flex-shrink: 0;
        width: var(--icon-width);
        height: var(--icon-height);
        background-image: var(--icon-bg), var(--icon-bg);
        background-repeat: no-repeat, no-repeat;
        background-size: var(--icon-width) var(--icon-height);
        transition: background-position .4s cubic-bezier(.86, 0, .07, 1);
        will-change: transform;
        content: "";
        background-position: 0 0, calc(var(--icon-width)* -2) calc(var(--icon-width)* -2);
        margin-right: var(--space-2xs);
        display: block;
      }
    }
  }

  .hs-form-field,
  .legal-consent-container {
    margin-bottom: var(--flow);
  }

  .hs-form-booleancheckbox {
    span {
      margin: 0;
    }

    .hs-form-booleancheckbox-display {
      font-family: var(--body-font-family);
      color: var(--Pale-black, #000);
      font-size: 19px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%; /* 26.6px */
      box-sizing: border-box;
      margin: var(--flow) 0 0 0;
      padding: 0;
      display: block;
      min-height: 1.5rem;
      margin-bottom: .125em;
      padding-left: 1.5em;
    }
  }

  .inputs-list {
    padding: 0;
    list-style: none;

    &.hs-error-msgs {
      padding: 0;
      list-style: none;
  
      label {
        color: #FF5348;
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;
      }
    }
  }
}

html:not(.is-mobile) #hubspot-messages-iframe-container {
  margin-right: calc(var(--container-padding) - 16px);
  margin-bottom: calc(var(--site-menu-header-padding) - 16px);
}

.column-container {
  flex-direction: column;
  gap: var(--space-s);
}

.c-form-contact {
  .c-form-contact__title {
    margin-bottom: 20px;
  }
}