.o-text-reveal {
  --ready: var(--show-when-ready, 0);
  --speed: 1.6s;

  &:not(.is-initialized) {
    visibility: hidden;
  }

  &.o-text-reveal--fast {
    --speed: 1.2s;
  }
}

.o-text-reveal__wrapper {
  overflow: hidden;
}

.o-text-reveal__item {
  display: flow-root;
  will-change: transform;
  transition: transform calc(var(--speed) * 0.8) cubic-bezier('easeInQuint');
  transform: translateY(110%); // "Ù"

  .is-ready & {
    transform: translateY(calc(100% - var(--ready) * 100%));
  }

  .is-ready .is-inview & {
    transform: translateY(0%);
    transition: transform var(--speed) cubic-bezier('easeOutQuint') calc((var(--index) + var(--index-base, 0)) * 0.13s);

    // transition-timing-function: cubic-bezier('easeOutQuint');
  }
}
