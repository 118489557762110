.c-section-nav {
  position: relative;
  z-index: 2;

  .is-loading & {
    opacity: 0.5;
  }

  a {
    text-decoration: none;
  }
}

.c-section-nav__dropdown {
  position: relative;

  @include media-breakpoint-up(sm) {
    display: none;
  }

  .c-section-nav--show-search & {
    @include media-breakpoint-down(md) {
      display: flex;
    }
  }
}

.c-section-nav__toggle {
  display: flex;
  width: 100%;
  padding: var(--space-xs);
  color: var(--color-mid-blue-gray);
  line-height: 1.1;
  text-align: left;
  background: var(--color-white);
  border: none;
  border-radius: var(--border-radius);
  appearance: none;
  outline: none;
}

.c-section-nav__select {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}

.c-section-nav__icon {
  flex-shrink: 0;
  margin-left: var(--space-2xs);
  color: var(--color-black);
}

.c-section-nav__active {
  overflow: hidden;
  margin-left: auto;
  color: var(--color-black);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.c-section-nav__tags {
  --index: 3;
  --reveal-y: var(--space-xs);

  .c-section-nav--show-search & {
    @include media-breakpoint-up(md) {
      flex-grow: 1;
    }

    @include media-breakpoint-down(md) {
      display: none !important;
    }
  }
}

.c-section-nav__container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    align-items: flex-start;
  }

  @include media-breakpoint-down(md) {
    > * {
      flex: 1 1 0;
    }
  }

  @include media-breakpoint-down(sm) {
    > * {
      flex: none;
    }
  }
}
