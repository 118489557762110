/* stylelint-disable selector-class-pattern */
@use 'sass:map';

.MarkupPagerNav {
  --column-span: 12;

  display: flex;
  align-items: center;
  margin-top: var(--space-section-large);
  padding-right: var(--container-padding);
  padding-left: var(--container-padding);
  font-size: var(--step--1);
  font-weight: 500;
  text-transform: uppercase;
  list-style: none;

  @include media-breakpoint-down(sm) {
    justify-content: center;

    li:not(.MarkupPagerNavPrevious, .MarkupPagerNavOn, .MarkupPagerNavNext) {
      display: none;
    }
  }

  .is-loading & {
    opacity: 0.5;
  }

  > * {
    margin: 0 var(--space-2xs);
  }

  a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: var(--space-m);
    height: var(--space-m);
    color: #000;
    line-height: 1;
    text-decoration: none;
    background: #fff;
    border-radius: 999px;

    span {
      display: inline-block;
      padding-top: 0.15em;
    }
  }

  .MarkupPagerNavOn a {
    color: #fff;
    background: #000;
  }

  .MarkupPagerNavPrevious {
    a {
      transform: scaleX(-1);
    }

    span {
      display: none;
    }
  }

  .MarkupPagerNavNext {
    span {
      display: none;
    }
  }
}

.MarkupPagerNavNext,
.MarkupPagerNavPrevious {
  a {
    &::after {
      --icon-width: var(--space-s);
      --icon-height: var(--space-s);
      --icon-bg: var(--icon-arrow);

      @include arrow-icon;
    }
  }

  .can-hover & a:hover::after {
    @include arrow-icon-hover;
  }
}
