.c-cta__title {
  margin-bottom: var(--space-xl);
  font-size: var(--step-7);
}

.c-cta__content {
  --cta-column-padding: 0;

  display: flex;
  margin-left: calc(var(--column-width-gutter) * var(--cta-column-padding));

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-end;

    a:not(:last-child) {
      margin-bottom: var(--gutter-y);
    }
  }

  @include media-breakpoint-up(sm) {
    --cta-column-padding: 4;

    a:not(:last-child) {
      margin-right: var(--gutter-x);
    }
  }

  @include media-breakpoint-up(md) {
    --cta-column-padding: 5;
  }

  @include media-breakpoint-up(lg) {
    --cta-column-padding: 5;
  }
}
