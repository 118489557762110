.c-banner-text {
  padding: var(--space-2xl) 0;
  background: var(--color-white);

  .c-header-hp + & {
    --flow: 0;
  }
}

.c-banner-text__wrapper {
  @include media-breakpoint-up(sm) {
    --column-span: 10;
    --column-start: 2;
  }

  @include media-breakpoint-up(md) {
    --column-span: 8;
    --column-start: 3;
  }
}

.c-banner-text__title {
  --measure: 18ch;

  text-wrap: balance;
  margin-bottom: var(--space-l);
  font-size: var(--step-6);

  @include media-breakpoint-up(sm) {
    font-size: var(--step-7);
  }

  strong  {
    color: var(--color-mid-blue-gray);
    font-weight: inherit;
  }
}

.c-banner-text__cta {
  --index: 5;
}

.c-banner-text__press {
  --index: 7;

  margin-top: var(--space-m);

  @include media-breakpoint-up(sm) {
    --column-span: 10;
    --column-start: 2;

    margin-top: 0;
  }

  @include media-breakpoint-up(md) {
    --column-span: 7;
    --column-start: 6;
  }

  @include media-breakpoint-up(lg) {
    --column-span: 6;
    --column-start: 7;
  }

  @include media-breakpoint-up(xl) {
    --column-span: 5;
    --column-start: 8;
  }
}
