.wrapper-cases {
  display:grid;
  grid-template-columns: 4fr 8fr;
  gap: 24px;

  .right-content {
  
    .case {
      &:not(.active) {
        display: none;
      }

      .items-case {
        display: flex;
        flex-direction: row;
      }

      .news-results__list {
        margin-inline: 0;
      }

    }

    .news-results {
      flex: 1 1 60%;
    }

    .categories-case {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px;
      border: 1px solid #ffff;
      text-align: center;
      align-items: center;
      flex: 1 0 40%;

      .disabled {
        pointer-events: none;
      }

    }

  }

  .no-padding {
    padding: 0;
  }

  ul {
    display: flex;
    flex-direction: column;
  }

  .white-title {
    font-size: 70px;
    color: #ffff;
  }

  .news-results {
    --color-results: var(--color-white);
    .news-results__list {

      li {
        border-bottom: 1px solid #ffff;
        border-right: 1px solid #fff;
    
        &:first-child {
          border-top: 1px solid #fff;
        }
    
      }

    }
  }

  @include media-breakpoint-down(lg) {
    display: flex;
    flex-direction: column;

    .left-content {

      .c-section-nav__tags {
        padding: 10px 0px 30px 0px;
      }

    }

    .right-content {

      .case {
        .items-case {
          flex-direction: column;
        }

      
        .items-case {
          flex-direction: column;
        }

        .news-results__list {
          margin-inline: 0;
        }

      }

      .categories-case{
        padding-bottom: 20px;
        border-bottom: none;
        border-inline: none;
        margin-inline: calc(-1 * var(--container-padding));
      }

    }

    .news-results {
      .news-results__list {
        border-top: none;
        
        li {
          border-bottom: 1px solid #ffff;
          margin-inline: calc(-1 * var(--container-padding));
        }
  
    }
    
  }

  }

}