.o-link {
  --pill-width: calc(1.5em + var(--space-2xs) * 2);
  --icon-width: var(--space-s);
  --arrow-padding: calc((var(--pill-width) - var(--icon-width)));

  position: relative;
  z-index: 2;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  padding-right: var(--arrow-padding);
  line-height: 0.9;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 100px;

  &::after {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    display: block;
    width: 100%;
    background: var(--color-yellow);
    border-radius: 100px;
    transition: transform 0.5s cubic-bezier('easeOutQuint');
    transform: translateX(calc((100% - var(--pill-width)) * -1));
    content: '';
  }

  .can-hover &:hover::after{
    transition: transform 0.5s cubic-bezier('easeOutQuint');
    transform: translateX(0);
  }

  .o-pill {
    z-index: 2;
    margin-right: var(--space-2xs);
  }
}
