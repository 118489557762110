.c-works-list {
  --media-ratio: 1;
  --media-border-radius: var(--border-radius);
  --gutter-y: var(--space-xl-2xl);

  // margin-bottom: var(--space-2xl);

  ul {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }
}

.c-works-list__item {
  @include media-breakpoint-up(sm) {
    --column-span: 6;

    &:nth-child(even) {
      --index: 0.75;
    }
  }
}

.c-works-list__item-title {
  position: relative;
  margin-top: var(--space-xs);
  font-size: var(--step-2);
  font-weight: 400;

  .can-hover &::before {
    position: absolute;
    top: 0.35em;
    width: 0.5em;
    height: 0.5em;
    background: var(--color-yellow);
    border-radius: 100%;
    transform: scale(0);
    transition: transform 0.3s;
    content: '';
  }

  .can-hover a:hover &::before {
    transform: scale(1);
  }
}

.c-works-list__item-label {
  display: block;
  margin-right: 0.9em;

  .can-hover & {
    transition: transform 0.3s;
  }

  .can-hover a:hover & {
    transform: translateX(0.9em);
  }
}

.c-works-list__item--cta {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-start;

  @include media-breakpoint-up(sm) {
    &:nth-child(odd) {
      --column-start: 7;
    }

    &:nth-child(even) {
      &::before {
        padding-bottom: 100%;
        content: '';
      }
    }
  }
}
