.c-page-header-text {
  --title-size: var(--step-10);

  padding: calc(var(--site-header-height) + var(--space-s-m)) 0 var(--space-3xl-4xl);
  color: var(--color-white);

  &.c-page-header-text--small {
    --title-size: var(--step-7);
  }

  + * {
    margin-top: 0;
  }
}

.c-page-header-text__heading {
  @include media-breakpoint-up(sm) {
    margin-left: calc(var(--column-width-gutter) * 2);
  }

  @include media-breakpoint-up(lg) {
    margin-left: calc(var(--column-width-gutter) * 3);
  }
}

.c-page-header-text__title {
  display: inline-block;
  height: var(--heading-line-height);
  font-size: var(--title-size);
  vertical-align: bottom;

  * {
    display: inline-block !important;
  }

  .c-page-header-text--small & {
    font-weight: 400;
  }
}

.c-page-header-text__counter {
  --index: 3;

  position: relative;
  top: calc(var(--title-size) * -0.18);
  display: inline-block;
  color: var(--color-black);
  font-size: var(--step--1);
  vertical-align: baseline;
}
