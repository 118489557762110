.o-icon {
  display: inline-block;
  flex-shrink: 0;
  stroke-width: var(--icon-stroke-width, 0.3px);
  width: var(--icon-width, var(--space-s));
  height: var(--icon-height, var(--space-s));
  vertical-align: middle;
  font-size: 0;
  fill: currentcolor;
  stroke: currentcolor;
}

.o-icon__svg {
  width: 100%;
  height: 100%;
}

.o-icon--logo {
  --icon-stroke-width: 0.1px;
  --ratio: calc(#{$logo-width} / #{$logo-height});
  --icon-height: calc(var(--site-menu-button-size) * 0.7);
  --icon-width: calc(var(--icon-height) * var(--ratio));
}
