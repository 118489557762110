.c-site-header {
  position: absolute;
  z-index: $zindex-site-header;
  display: flex;
  align-items: center;
  box-sizing: content-box;
  height: var(--site-menu-button-size);
  padding: var(--site-menu-header-padding) var(--container-padding) 0;

  .invert-logo & {
    color: var(--color-white);
  }
}

.c-site-header__logo {
  display: block;
  transition: opacity 0.25s;
  will-change: opacity;

  .show-menu & {
    opacity: 0;
  }

  .tpl-home & {
    pointer-events: none;
  }
}
