.c-search-bar {
  --padding-rl: calc(var(--space-m) * 0.7);

  position: relative;
  overflow: hidden;
  background: var(--color-white);
  border-radius: 100px;

  @include media-breakpoint-up(sm) {
    width: var(--space-4xl) !important;
  }

  @include media-breakpoint-down(md) {
    padding: var(--space-xs);
    line-height: 1.1;
    border-radius: var(--border-radius);
  }
}

.c-search-bar__input {
  width: 100%;
  padding: 0 var(--padding-rl);
  background: #fff;
  border: none;
  appearance: none;

  @include media-breakpoint-up(md) {
    padding: 0.4em var(--space-m) 0.36em;
    font-size: var(--step--1);
    font-weight: 500;
    line-height: 1.2;
  }

  &::placeholder {
    color: var(--color-mid-blue-gray);
  }

  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  &:focus {
    outline: 0;
  }
}

.c-search-bar__lens,
.c-search-bar__reset {
  position: absolute;
  top: 50%;
  transform: translateY(-53%);
}

.c-search-bar__lens {
  left: var(--space-xs);
}

.c-search-bar__reset {
  right: var(--space-xs);
  display: none;

  * {
    pointer-events: none;
  }

  .c-search-bar--has-value & {
    display: block;
  }
}
