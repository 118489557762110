.info-container {

  &.mode-1 {
    --color-results:  var(--color-white);
    background-color: var(--color-black);
    color: var(--color-results);
  }

  &.mode-2 {
    --color-results: var(--color-black);
    background-color: var(--color-white);
    color: var(--color-results);
    
  }

  .info-title {
    font-size: 72px;
    text-wrap: unset;
  }

  .info-wrapper {
    display: flex;
    padding-top: 30px;

    .container-text-image {
      display: flex;
      align-items: center;
      gap: 20px;
      flex: 1;
      align-self: center;
      --column-span: 5;
    }

    .o-media {
      align-content: center;
      justify-self: center;
      width: 75%;
    }

    .info-text {
      flex: 1;
      --column-span: 6;
      font-size: 24px;
      line-height: 125%;

      h3 {
        font-size: 30px;
        margin-bottom: 25px;

        @include media-breakpoint-up(md) {
          font-size: 64px;
        }
      }

      ul {
        padding: 1.1em;
      }
    }
    
  }

  .image {
    flex: 1;
    align-self: center;
  }

  .text-image {
    flex: 1;
    align-self: center;
    font-size: 80px;
  }

  @include media-breakpoint-down(md) {
    .info-title {
      font-size: 30px;
    }

    .info-wrapper {
      flex-direction: column;
      gap: 20px;
      padding-top: 0;

      .container-text-image {
        gap: 20px;
      }

        .o-grid {
          display: unset;
        }

        .info-text {
          font-size: 20px;
        }

      .image {
        margin-right: 0;
        gap: 20px;
        padding-bottom: 20px;
      }

      .text-image {
        font-size: 60px;
      }
    }
  }
}