.c-basic {
  --columns-left: 0;

  padding-top: calc(var(--site-header-height) + var(--site-menu-header-padding));
  padding-left: calc(var(--column-width-gutter) * var(--columns-left) + var(--container-padding));

  @include media-breakpoint-up(sm) {
    --columns-left: 2;
  }

  .c-overlay & {
    padding: 0;
  }

  .o-floating-cta {
    --index: 2;

    padding-left: 0;
  }

  &.fancybox__content {
    background: transparent;
  }
}

.c-basic__content {
  padding-bottom: var(--space-section-large);
  background: var(--color-white);
  border-radius: var(--border-radius) 0 0 var(--border-radius);
}

.c-basic__body {
  --columns-left: 0;
  --index: 3;

  padding-left: calc(var(--column-width-gutter) * var(--columns-left) - var(--container-padding));

  @include media-breakpoint-up(sm) {
    --columns-left: 2;
  }

  @include media-breakpoint-up(md) {
    --columns-left: 4;
  }
}

.c-basic__title {
  padding: calc(var(--container-padding) * 0.8) 0 var(--space-xl);
}

.c-basic__cta {
  padding-top: var(--space-m);

  .c-overlay & {
    display: none;
  }
}
