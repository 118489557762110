.c-text-highlight {
  + * {
    --flow: var(--space-section-medium);
  }

  p {
    @extend %heading;

    font-size: var(--step-5);
  }

  strong {
    color: var(--color-white);
    font-weight: inherit;
  }

  a {
    --line-color: #fff;
    --line-color-hover: #000;
    --line-thickness: 0.05em;
    --line-position: 92%;

    display: inline-block;
    text-decoration: none;
    background-image: linear-gradient(var(--line-color-hover), var(--line-color-hover)), linear-gradient(var(--line-color), var(--line-color));
    background-position: 0% var(--line-position), 0% var(--line-position);
    background-repeat: no-repeat;
    background-size: 0 var(--line-thickness), 100% var(--line-thickness);
    transition: background-size 0.6s;
    transition-timing-function: cubic-bezier('easeInOutQuart');

    .can-hover &:hover {
      background-size: 100% var(--line-thickness), 100% var(--line-thickness);
    }
  }
}

.c-text-highlight--big p {
  @include media-breakpoint-up(sm) {
    font-size: var(--step-6);
  }
}
