.c-work-list-hp {
  ul {
    list-style: none;
  }
}

.c-work-list-hp__item {
  --column-span: 6;
}

.c-work-list-hp__item-cta {
  position: relative;
  padding-bottom: 100%;

  & > *:first-child {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
