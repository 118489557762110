.c-landing-header {
  position: relative;

  .invert-logo & {
    color: var(--color-white);
  }
}

.c-landing-header__media {
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @include media-breakpoint-down(md) {
    .c-landing-header--image-hide & {
      display: none;
    }
  }

  @include media-breakpoint-up(md) {
    left: 50%;

    .c-landing-header--image-full & {
      left: 0;
    }
  }

  @include media-breakpoint-down(md) {
    left: 0;
  }

  .o-media--bg {
    height: 100%;
    padding-bottom: 0;
  }

  .o-media__image {
    background-position: center;
    background-size: contain;

    .c-landing-header--image-full & {
      background-size: cover;
    }

    .c-landing-header--image-bottom-right & {
      background-position: bottom right;
    }
  }
}

.c-landing-header__content {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: var(--space-section-large) 0;

  @include media-breakpoint-up(md) {
    width: 50%;
  }
}

.c-landing-header__title {
  font-size: var(--step-6);
  text-wrap: balance;

  @include media-breakpoint-up(sm) {
    font-size: var(--step-6);
  }
}

.c-landing-header__body {
  --index: 3;
}
