.c-addresses {
  + * {
    --flow: var(--space-section-large);
  }

  ul {
    list-style: none;
  }
}

.c-addresses__item-list {
  --index-base: 2;
  --gutter-y: var(--space-m);

  @include media-breakpoint-up(sm) {
    --gutter-y: var(--space-xl);
  }
}

.c-addresses__item {
  --column-span: 12;

  @include media-breakpoint-up(sm) {
    --column-span: 4;

    &:nth-child(odd) {
      --column-start: 4;
    }

    &:nth-child(even) {
      --column-start: 9;
    }
  }

  @include media-breakpoint-up(md) {
    &:nth-child(odd) {
      --column-start: 5;
    }
  }

  @include media-breakpoint-up(lg) {
    --column-span: 3;

    &:nth-child(2n + 1) {
      --column-start: 6;
    }
  }
}

.c-addresses__item-title {
  color: var(--color-white);
  font-size: var(--step-0);
  font-weight: 400;
  text-transform: uppercase;
}

.c-addresses__item-text {
  @extend %heading;

  margin-top: var(--space-xs);
  font-size: var(--step-2);
  font-weight: 400;
}
