@mixin arrow-icon($angle: false) {
  flex-shrink: 0;
  width: var(--icon-width);
  height: var(--icon-height);
  background-image: var(--icon-bg), var(--icon-bg);
  background-repeat: no-repeat, no-repeat;
  background-size: var(--icon-width) var(--icon-height);
  transition: background-position 0.4s cubic-bezier('easeInOutQuint');
  will-change: transform;
  content: '';

  @if $angle {
    background-position: 0 0, calc(var(--icon-width) * -2) calc(var(--icon-width) * -2);
  } @else {
    background-position: 0 0, calc(var(--icon-width) * -2) 0;
  }
}

@mixin arrow-icon-hover($angle: false) {
  @if $angle {
    background-position: calc(var(--icon-width) * 2) calc(var(--icon-width) * 2), 0 0;
  } @else {
    background-position: calc(var(--icon-width) * 2) 0, 0 0;
  }
}

@mixin menu-footer-links() {
  a {
    --translate: var(--space-xs-s);
    --show-dot: 0;

    position: relative;
    display: inline-block;
    margin-right: var(--translate);
    transform: translateX(calc(var(--translate) * var(--show-dot)));

    // dot
    &::before {
      content: '';
      position: absolute;
      top: 0.45em;
      left: 0;
      width: var(--space-3xs-2xs);
      height: var(--space-3xs-2xs);
      background-color: currentcolor;
      border-radius: 100%;
      transition: inherit;
      transform: translateX(calc(var(--translate) * var(--show-dot) * -1)) scale(var(--show-dot));
    }
  }

  .can-hover & a {
    transition: transform 0.3s;
    will-change: transform;

    // safe area for hover
    &::after {
      position: absolute;
      left: 0;
      width: calc(var(--translate) * 2);
      height: 100%;
      opacity: 0.2;
      content: '';
      transition: inherit;
      transform: translateX(calc(var(--translate) * var(--show-dot) * -1));
    }
  }

  .can-hover & a:hover,
  .is-active a {
    --show-dot: 1;
  }
}
