.page-list {
    --flow: 0;
    
    &.mode-1 {
      --color-results:  var(--color-white);
      background-color: var(--color-black);
      color: var(--color-results);
    }
  
    &.mode-2 {
      --color-results: var(--color-black);
      background-color: var(--color-white);
      color: var(--color-results);
      
    }
  
}

.c-text-list {

  .title-list {
    font-size: 72px;
    margin-bottom: 20px;
  }

  .text-body {
    font-size: 24px;
    line-height: 125%;
  }

  @include media-breakpoint-down(lg) {
    .title-list {
      font-size: 30px;
    }
    
    .text-body {
      font-size: 20px;
    }

  }

}