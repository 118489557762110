.c-card {
  --icon-width: calc(var(--space-m) * 0.8);
  --icon-height: calc(var(--space-m) * 0.8);
  --icon-bg: var(--icon-arrow-45);

  position: relative;
  width: 100%;
  color: var(--color-black);
  background: var(--color-white);
  border-radius: var(--border-radius);

  &::before {
    float: left;
    padding-bottom: 100%;
    content: '';
  }

  &::after {
    display: table;
    content: '';
    clear: both;
  }
}

.c-card--invert {
  color: var(--color-white);
  background: var(--color-black);
}

.c-card__title {
  padding: var(--space-s) var(--space-s) 0;
  font-size: var(--step-3);

  a ~ &:last-child {
    padding-bottom: calc(var(--space-s) + var(--icon-width));
  }

  .c-card--invert & {
    text-transform: uppercase;

    @include media-breakpoint-up(xl) {
      max-width: 75%;
    }
  }
}

.c-card__text {
  margin-top: var(--space-s);
  padding: 0 var(--space-s);

  a ~ &:last-child {
    padding-bottom: calc(var(--space-s) + var(--icon-width));
  }
}

.c-card__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .c-card--invert & {
    filter: invert(1);
  }

  &::before {
    @include arrow-icon(true);

    position: absolute;
    right: var(--space-2xs);
    bottom: var(--space-2xs);
  }

  .can-hover &:hover::before {
    @include arrow-icon-hover(true);
  }
}
