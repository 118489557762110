.c-cards {
  + * {
    --flow: var(--space-section-large);
  }

  a {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-decoration: none;
  }
}

.c-cards__title {
  margin-bottom: var(--space-xl);
  font-size: var(--step-7);
}

.c-cards__grid {
  @include media-breakpoint-up(lg) {
    margin-right: var(--column-width-gutter);
    margin-left: var(--column-width-gutter);
  }
}

.c-cards__item {
  --column-start: auto;
  --column-span: 12;

  display: flex;

  @include media-breakpoint-up(sm) {
    --column-span: 6;
  }

  @include media-breakpoint-only(sm) {
    &:nth-child(1) {
      --column-start: 7;
    }
  }

  @include media-breakpoint-up(md) {
    --column-span: 4;

    &:nth-child(4n - 3) {
      --column-start: 5;
    }
  }
}
