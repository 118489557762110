.faq-container {
  &.mode-1 {
    --color-results:  var(--color-white);
    background-color: var(--color-black);
    color: var(--color-results);
  }

  &.mode-2 {
    --color-results: var(--color-black);
    background-color: var(--color-white);
    color: var(--color-results);
    
  }

  .faq-title {
    color: var(--color-white);
    font-size: 72px;
    padding-bottom: 40px;
  }

  @media screen and (max-width: 768px) {
    .faq-title {
      font-size: 30px;
    }
    
  }

}
  
.accordion-wrapper {
  height: 0px;
  overflow: hidden;
  transition: height .3s cubic-bezier(.87,0,.13,1);
  background-color: var(--color-black);
  color: var(--color-white);

  .accordion-text {
    padding-block: 20px;
    font-size: 24px;
  }

  &.open {
    > :last-child {
      border-block-end: 1px solid var(--color-white);
    }
  }

  @media screen and (max-width: 768px) {
    padding-left: 20px;

    .accordion-text {
      font-size: 14px;
    }  
  }

}
  
.faq-wrapper {

  .faq-text {
    border-bottom: 1px solid var(--color-white);
  }

  @media screen and (max-width: 768px) {
      .faq-text {
        font-size: 16px;
      }
  }

}
  
.btn-faq {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: -webkit-fill-available;
  font-size: 30px;
  color: var(--color-white);
  background-color: var(--color-black);
  border-block-start: 1px solid var(--color-white);
  border-left: none;
  border-inline: none;
  text-align: left;

  span {
    color: #FFDF36;
    font-size: 40px;
  }

  .plus {
    display: block;
  }

  .minus {
    display: none;
  }

  @media screen and (max-width: 768px) {
    padding: 20px;
    font-size: 24px;
  }

}
  
.open {
  
    .minus {
      display: block;
    }
  
    .plus {
      display: none;
    }
}