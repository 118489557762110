.o-media__video-wrapper {
    background-color: var(--color-white);
    padding-block: 40px;
  
    .o-media__media {
      --parallax: 12%;
    }
  
    @media screen and (max-width: 768px) {
      margin-inline: calc(-1* var(--container-padding));
    }
}