html {
  &.is-loading {
    cursor: wait !important;

    *,
    *::before,
    *::after {
      pointer-events: none;
    }
  }
}

/* stylelint-disable-next-line selector-class-pattern */
.💧 {
  overflow-y: scroll;
  padding-right: 0 !important;
  scroll-padding-top: calc(var(--site-header-height) * 2);

  body {
    overscroll-behavior-y: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    min-height: 100vh;
    padding-right: var(--scrollbar-width, 0);
  }

  &:not(.is-ready) body {
    opacity: 0;

    * {
      transition: none !important;
    }
  }
}

[data-srcset],
[data-bgset] {
  opacity: 0.01;
  transition: opacity 0.3s;

  &.lazyloaded {
    opacity: 1;
  }
}

[data-bgset] {
  background: center/cover no-repeat;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

a {
  color: currentcolor;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

.no-spacing {
  --flow: 0px;
}

.padding-section {
  padding-bottom: var(--space-section-medium);
  padding-top: 50px;
}

.page-list+.c-site-footer, .padding-section+.c-site-footer, .info-container+.c-site-footer {
  --flow: 0 !important;
}

.mode-1 {
  --color-results:  var(--color-white);
  background-color: var(--color-black);
  color: var(--color-results);
}

.mode-2 {
  --color-results:  var(--color-black);
  background-color: var(--color-white);
  color: var(--color-results);
}