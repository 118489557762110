.c-overlay {
  --content-delay: 0s;

  position: fixed;
  z-index: $zindex-overlay;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  &:not(.c-overlay--show) {
    pointer-events: none;
  }
}

.c-overlay__button {
  position: absolute;
  z-index: 2;
  top: var(--site-menu-header-padding);
  right: calc(var(--container-padding) + var(--scrollbar-width, 0px));
  width: var(--site-menu-button-size);
  height: var(--site-menu-button-size);
  padding: 0;
  background-color: var(--color-light-gray);
  border: none;
  border-radius: 100%;
  opacity: 0;

  // transition: opacity 0.25s;
  appearance: none;

  // &::before,
  // &::after {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   width: 40%;
  //   height: 1px;
  //   background: var(--color-black);
  //   content: '';
  //   transform-origin: 50% 50%;
  // }

  // &::before {
  //   transform: translate(-50%, -50%) rotate(-45deg);
  // }

  // &::after {
  //   transform: translate(-50%, -50%) rotate(45deg);
  // }

  // .c-overlay--show & {
  //   opacity: 1;
  // }
}

.c-overlay__wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: auto;
  width: 100%;
  -webkit-overflow-scrolling: touch;

  .c-overlay:not(.c-overlay--show) & {
    pointer-events: none;
  }

  .c-overlay:not(.c-overlay--loaded) & {
    overflow: hidden;
  }
}

.c-overlay__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  opacity: 0;
  transition: opacity 0.25s;

  .c-overlay--show & {
    opacity: 0.9;
  }
}

.c-overlay__content {
  contain: paint;
}

.c-overlay__ajax {
  --top: calc(var(--site-header-height) + var(--site-menu-header-padding));
  --columns-left: 0;

  border-top-left-radius: var(--border-radius);
  transform: translateY(100vh);
  position: relative;
  min-height: calc(100vh - var(--top));
  margin-top: var(--top);
  margin-left: calc(var(--column-width-gutter) * var(--columns-left) + var(--container-padding));
  background-color: #fff;
  opacity: 0;

  @include media-breakpoint-up(sm) {
    --columns-left: 2;
  }

  @include media-breakpoint-up(lg) {
    --columns-left: 3;

    .c-basic__body {
      --columns-left: 3;
    }
  }

  .c-overlay--show & {
    opacity: 1;
    transform: none;
    transition: transform 1.2s cubic-bezier('easeOutQuint') var(--content-delay), opacity 0s var(--content-delay);
  }

  // loading
  &::before {
    position: absolute;
    top: var(--container-padding);
    left: var(--container-padding);
    content: '';
    display: block;
    width: var(--space-m);
    height: var(--space-m);
    margin-left: var(--grid-container-paddings);
    border: 2px solid currentcolor;
    border-bottom-color: transparent;
    border-radius: 50%;
    /* stylelint-disable-next-line no-unknown-animations */
    animation: spin 0.5s linear infinite;

    .c-overlay--loaded & {
      display: none;
    }
  }
}
