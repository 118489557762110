.c-technologies {
  padding-top: var(--space-xl);
  padding-bottom: var(--space-xl);
  background: var(--color-white);

  .swiper-wrapper {
    @include media-breakpoint-up(sm) {
      flex-flow: wrap;
      justify-content: center;
      gap: 10px;
      pointer-events: none;
      transform: none !important;
    }

    @include media-breakpoint-up(lg) {
      flex-flow: nowrap;
    }
  }

  .swiper-slide {
    opacity: 0.8;

    @include media-breakpoint-up(sm) {
      width: 200px !important;
    }

    @include media-breakpoint-up(lg) {
      flex: 1 1 0;
    }
  }

  .swiper-pagination {
    --swiper-pagination-bottom: 0;

    position: relative !important;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: var(--color-black);
  }
}

.c-technologies__title {
  margin-bottom: var(--space-l);
  font-size: var(--step-0);
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.c-technologies__logo {
  padding-bottom: 100%;
  background-size: contain;
}
