.c-header-hp {
  --media-mask: 0.3;
  --media-reveal: #000;

  position: relative;
  overflow: hidden;
  color: var(--color-white);
  background: var(--color-black);

  &::after {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    content: '';
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.4s 1.5s;
  }

  &.is-outro {
    pointer-events: none;

    &::after {
      opacity: 1;
    }
  }

  .o-media {
    position: static;
    display: none;
  }

  .o-gl.is-disabled ~ .o-media {
    display: block;
  }
}

.c-header-hp__container {
  position: relative;

  @include media-breakpoint-up(lg) {
    padding: 0 calc(var(--container-padding) + var(--column-width-gutter));
  }

  @include media-breakpoint-up(xl) {
    padding: 0 calc(var(--container-padding) + var(--column-width-gutter) * 2);
  }
}

.c-header-hp__media {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.c-header-hp__seo-title {
  position: absolute;
}

.c-header-hp__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: var(--space-section-large) 0;
  font-size: var(--step-8);

  [aria-label]::before {
    content: attr(aria-label);
  }

  [data-ref] {
    will-change: transform;
  }
}

.c-header-hp__title-row {
  display: block;

  .o-text-reveal__wrapper {
    display: block;
  }

  &:first-child {
    --index: 3;
  }

  &:last-child {
    --index-base: 4;
  }
}

.c-header-hp__title-word {
  display: inline-flex;
  flex-direction: column;
  overflow: hidden;
  height: var(--heading-line-height);
}

.c-header-hp__cta-wrapper {
  padding-bottom: var(--space-section-large);
}

.c-header-hp__cta-title {
  margin-bottom: var(--space-m);
  font-size: var(--step-4);
}

.c-header-hp__cta-list {
  --gutter-y: var(--space-s);

  @include media-breakpoint-up(md) {
    --column-span: 4;
  }

  .c-header-hp__cta-card {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.c-header-hp__cta-button {
  text-align: right;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.c-header-hp__cta-card {
  --icon-width: calc(var(--space-m) * 0.7);
  --icon-height: calc(var(--space-m) * 0.7);
  --icon-bg: var(--icon-arrow-45);
  --original-height: calc((var(--step-2) * 4) + (var(--space-s) * 2));

  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: var(--border-radius);
}

.c-header-hp__cta-card-title {
  --measure: 14ch;

  padding: var(--space-s);
  color: var(--color-black);
  font-size: var(--step-2);
  line-height: 1em;
  text-transform: uppercase;
}

.c-header-hp__cta-card-link {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: var(--original-height);
  text-decoration: none;

  .can-hover &:hover {
    height: 100%;
  }
}

.c-header-hp__cta-card-text {
  padding: var(--space-s) var(--space-m) var(--space-m) var(--space-s);
  color: var(--color-black);
  font-size: var(--step--1);
  line-height: 1.4;
  opacity: 0;

  .can-hover & {
    transition: opacity 0.2s;
  }

  .can-hover .c-header-hp__cta-card-link:hover ~ & {
    opacity: 1;
    transition-delay: 0.1s;
  }
}

.c-header-hp__cta-card-bg {
  position: absolute;
  z-index: -1;
  top: 0;
  transform: translateY(calc((100% - var(--original-height)) * -1));
  width: 100%;
  height: 100%;
  background: var(--color-white);
  border-radius: var(--border-radius);
  opacity: 0.8;

  &::before {
    @include arrow-icon(true);

    position: absolute;
    z-index: 1;
    right: var(--space-2xs);
    bottom: var(--space-2xs);
  }

  .can-hover & {
    transition: opacity 0.4s, transform 0.6s cubic-bezier('easeOutQuint');
  }

  .can-hover .c-header-hp__cta-card-link:hover ~ & {
    opacity: 1;
    transform: translateY(0);

    &::before {
      @include arrow-icon-hover(true);
    }
  }
}
