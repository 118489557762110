.c-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: var(--scrollbar-width, 0);
  bottom: 0;
  left: 0;
  display: none;
  background-color: var(--color-black);
  opacity: 0;
}
