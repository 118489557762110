/* SCROLLING SECTION HOME PAGE */
.c-technologies {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.scroll-parent {
  position: relative;
  height: 140px;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 100;

  .c-technologies__logo {
    width: 120px;
  }

  .scroll-element {
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: var(--space-l);
    inset-block-start: 0;
    inset-block-end: 0;
    font-size: var(--step--0);

    &[data-direction="1"] {
      animation-name: loop-rtl;
    }

    &[data-direction="2"] {
      animation-name: loop-ltr;
    }

    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 150s;

    @include media-breakpoint-down(md) {
      animation-duration: 30s;
      gap: var(--space-m);
    }

    li {
      display: flex;
      align-items: center;
    }
  }
}

@include media-breakpoint-down(lg) {
  .hide-mobile {
    display: none !important;
  }
}

@include media-breakpoint-up(lg) {
  .hide-desktop {
    display: none !important;
  }
}

@keyframes loop-rtl {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes loop-ltr {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

/* POST LAYOUT */
.col3 {
  --column-span: 3;

  @include media-breakpoint-down(md) {
    --column-span: 6;
  }
}
