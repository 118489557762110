.c-tags {
  --tag-gap: var(--space-s);

  display: flex;
  flex-flow: wrap;
  flex-direction: var(--tag-direction, row);
  align-items: flex-start;
  margin-top: calc(var(--tag-gap) * -1);

  @include media-breakpoint-down(sm) {
    --tag-gap: var(--space-2xs);
  }
}

.c-tags__item {
  --transition: transform 0.5s cubic-bezier('easeOutQuint');
  --padding: 0.4em var(--space-xs) 0.36em;

  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-top: var(--tag-gap);
  font-size: var(--step--1);
  font-weight: 500;
  line-height: 1.2;
  text-decoration: none;
  white-space: nowrap;
  background: var(--color-white);
  border-radius: 100px;
  pointer-events: auto;

  &:not(:last-child) {
    margin-right: var(--tag-gap);
  }

  .can-hover &, {
    --hover: 0;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: var(--padding);
      content: attr(data-label);
      transform: translateY(calc(100% * (1 - var(--hover))));
      opacity: calc(var(--hover) * 0.5 + 0.5);
      transition: transform 0.5s cubic-bezier('easeOutQuint'), opacity 0.5s;
    }

    &:hover,
    &:focus {
      --hover: 1;
    }
  }
}

.c-tags__label {
  display: inline-block;
  padding: var(--padding);

  .can-hover & {
    opacity: calc(1 - var(--hover) * 0.5);
    transition: transform 0.5s cubic-bezier('easeOutQuint'), opacity 0.5s;
    transform: translateY(calc(-80% * (var(--hover))));
  }
}

.c-tags__item--is-active {
  color: var(--color-white);
  background: var(--color-black);
}
