.c-text {
  --gutter-y: var(--space-m);

  + * {
    --flow: var(--space-section-large);
  }
}

.c-text__col-cat {
  @include media-breakpoint-up(sm) {
    --column-span: 3;

    .c-tags {
      --tag-direction: column;
      --tag-gap: var(--space-3xs);
    }
  }
}

.c-text__col-body {
  --index: 2;

  @include media-breakpoint-up(sm) {
    --column-span: 8;
    --column-start: 4;
  }

  @include media-breakpoint-up(md) {
    --column-span: 7;
    --column-start: 5;
  }

  @include media-breakpoint-up(lg) {
    --column-span: 6;
    --column-start: 6;
  }
}
