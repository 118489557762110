.o-pill {
  $alphabet: (a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z);

  --pill-font-size: var(--step-0);
  --pill-height: calc(1.1em + var(--space-3xs) * 2);
  --pill-width: calc(1.5em + var(--space-2xs) * 2);
  --icon-width: var(--space-s);
  --icon-height: var(--space-s);
  --icon-bg: var(--icon-arrow);

  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  overflow: hidden;
  width: var(--pill-width);
  height: var(--pill-height);
  font-size: var(--pill-font-size);
  line-height: 1.1;
  text-transform: uppercase;
  background: var(--color-yellow);
  border-radius: 100px;

  &::after,
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::after {
    margin-top: 0.01em;
  }

  a & {
    &::after {
      display: none;
    }

    &::before {
      @include arrow-icon;
    }
  }

  .can-hover a:hover &::before {
    @include arrow-icon-hover;
  }

  @for $i from 1 through length($alphabet) {
    li:nth-child(#{$i}) &::after {
      content: '#{nth($alphabet, $i)}';
    }
  }
}
