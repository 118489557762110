.wrapper-header {
  width: 100%;

  .mini-title {
    display: flex;
    justify-content: center;
    font-size: 30px
  }

  .c-page-header-media__title {
    padding: 10px;
    font-size: var(--step-8);
    text-align: center;
    padding-left: 50px;
    padding-right: 50px;
  }

  .c-page-header-media__content {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .text-header {
    text-align: center;
    font-size: 24px;
  }

  @include media-breakpoint-down(lg) {

    .mini-title {
      font-size: 20px
    }

    .c-page-header-media__title {
      font-size: 40px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .text-header {
      font-size: 18px;
    }

  }
}

.page-header {

  @include media-breakpoint-down(lg) {
    .c-page-header-media {
      min-height: 100vh;
    }
  }  
}