.o-press-list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: var(--space-2xs);
  font-size: var(--step--1);

  li {
    display: inline;

    &::before {
      display: inline-block;
      width: 11px;
      height: 12px;
      margin-right: var(--space-2xs);
      background: url(#{svg('press')});
      content: '';
    }
  }
}
