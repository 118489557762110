.c-form-contact {
  padding-top: var(--space-m);
  padding-bottom: var(--space-section-large);
  background: var(--color-white);

  .c-text-w-image + & {
    margin-top: 0;
    padding-top: 0;
  }
}

.c-form-contact__title {
  --measure: 20ch;

  margin-bottom: var(--space-xl);
  font-size: var(--step-6);
  text-wrap: balance;

  @include media-breakpoint-up(sm) {
    font-size: var(--step-7);
  }

  .tpl-landing & {
    font-size: var(--step-5);
  }
}

.c-form-contact__wrapper {
  --column-span: 12;

  @include media-breakpoint-up(sm) {
    --column-span: 7;
    --column-start: 5;
  }

  @include media-breakpoint-up(lg) {
    --column-span: 6;
    --column-start: 6;
  }
}

.c-form-contact__body {
  margin-bottom: var(--space-l);
}
