.c-site-footer {
  --flow: var(--space-section-large) !important;

  padding: var(--space-m) 0;
  color: var(--color-white);
  background: var(--color-black);

  html:not(.is-mobile) & {
    overflow: hidden;
    min-height: 100vh;
    padding: 0;
  }

  .c-text-list + &,
  .c-form-contact + &,
  .c-technologies + & {
    --flow: 0 !important;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  .o-container {
    html:not(.is-mobile) & {
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 100vh;
      padding-top: var(--space-m);
      padding-bottom: var(--space-m);
      transform: translateY(-20vh);
    }
  }
}

.c-site-footer__logo {
  transition: opacity 0.25s;
  will-change: opacity;

  .show-menu & {
    opacity: 0;
  }

  .tpl-home & {
    pointer-events: none;
  }
}

.c-site-footer__label {
  font-size: var(--step--1);
  font-weight: 400;
  text-transform: uppercase;
  opacity: 0.5;
}

.c-site-footer__main {
  --gutter-y: var(--space-l);

  margin-top: var(--space-m);

  @include media-breakpoint-up(sm) {
    --gutter-y: var(--space-xs);

    margin-top: var(--space-xl);
  }

  li {
    @include menu-footer-links;
  }
}

.c-site-footer__nav-abilities,
.c-site-footer__nav-primary {
  ul { // very similar as site-menu
    margin-top: var(--space-s);
    font-size: var(--step-3);
  }

  li {
    @extend %heading;

    &:not(:last-child) {
      margin-bottom: var(--space-2xs);
    }
  }
}

.c-site-footer__nav-newsletter {
  @include media-breakpoint-up(sm) {
    --column-span: 4;
  }
}

.c-site-footer__nav-newsletter-subtitle {
  --measure: 25ch;

  margin-top: var(--space-s);
}

.c-site-footer__nav-newsletter-cta {
  margin-top: var(--space-s);
}

.c-site-footer__nav-abilities {
  @include media-breakpoint-up(sm) {
    --column-span: 4;
  }
}

.c-site-footer__nav-about {
  @include media-breakpoint-up(sm) {
    --column-span: 4;
  }
}

.c-site-footer__nav-secondary {
  margin-top: var(--space-s);

  li {
    line-height: var(--heading-line-height);

    &:not(:last-child) {
      margin-bottom: var(--space-2xs);
    }
  }
}

.c-site-footer__service {
  --gutter-y: 0;

  padding-top: var(--space-l);
  font-size: var(--step--2);

  html:not(.is-mobile) & {
    margin-top: auto;
  }
}

.c-site-footer__service-right {
  @include media-breakpoint-up(sm) {
    --column-span: 8;
  }
}

.c-site-footer__nav-legals {
  ul {
    display: flex;
    flex-flow: wrap;
  }

  li:not(:last-child) {
    margin-right: 1em;
  }

  .can-hover & a:hover {
    text-decoration: underline;
  }
}

.c-site-footer__colophon {
  margin-bottom: var(--space-3xs);
  opacity: 0.5;

  .can-hover & a:hover {
    text-decoration: underline;
  }
}

.c-site-footer__logos {
  margin-bottom: var(--space-l);

  @include media-breakpoint-up(sm) {
    --column-span: 4;

    order: 2;
    margin-bottom: 0;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.c-site-footer__bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: var(--space-xs-s);
  padding: var(--space-s) var(--space-s);
  color: var(--color-black);
  font-size: var(--step--1);
  background: var(--color-white);
  border-radius: 7px;

  ul {
    display: flex;
  }

  li {
    &:not(:last-child) {
      margin-right: var(--space-s);
    }
  }

  .can-hover & a:hover {
    text-decoration: underline;
  }
}

.c-site-footer__language {
  a {
    opacity: 0.4;
  }

  .is-active {
    text-decoration: underline;
    opacity: 1;
  }
}
