.text-right-image {

  &.mode-1 {
    --color-results:  var(--color-white);
    background-color: var(--color-black);
    color: var(--color-results);
  }

  &.mode-2 {
    --color-results: var(--color-black);
    background-color: var(--color-white);
    color: var(--color-results);
    
  }

  .c-text-w-image__body {

    h2 {
      font-size: 72px;
    }

  }

  .c-text-w-image__media {
    align-content: center;
    --column-span: 6;
    --column-start: 8;
  }

  .text-left-title {
    font-size: 72px;
  }

  .text-left-image {
    font-size: 24px;
    line-height: 125%;
  }

  @include media-breakpoint-down(lg) {

    .c-text-w-image__media {
      --column-span: 12;
      --column-start: 1;
    }

    .c-banner-text__title {
      font-size: 30px;
      text-wrap: unset;
    }

    .text-left-image {
      font-size: 20px;
      padding-bottom: 40px;
    }

  }

}