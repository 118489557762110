.c-results {
  + * {
    --flow: var(--space-section-large);
  }
}

.c-results__title {
  --measure: 20ch;

  text-wrap: balance;
  margin-bottom: var(--space-m);
  font-size: var(--step-7);

  .tpl-landing & {
    font-size: var(--step-5);
  }
}

.c-results__list {
  list-style-type: none;

  @include media-breakpoint-up(sm) {
    --column-span: 8;
    --column-start: 4;
  }

  @include media-breakpoint-up(md) {
    --column-span: 7;
    --column-start: 5;
  }

  @include media-breakpoint-up(lg) {
    --column-span: 6;
    --column-start: 6;
  }
}

.c-results__item {
  padding-top: var(--space-2xs);
  border-top: 1px solid var(--color-white);

  &:not(:last-child) {
    padding-bottom: var(--space-l);
  }
}

.c-results__item-title {
  color: var(--color-white);
  font-size: var(--step-9);
  line-height: 1;
}

.c-results__item-text {
  margin-top: var(--space-2xs);
}
