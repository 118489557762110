.c-popup {
  --flow: 0;

  position: fixed;
  z-index: $zindex-company-info;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: var(--color-black);
  font-size: 16px;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;

  &.show-popup {
    opacity: 1;
    pointer-events: auto;
  }
}

.c-popup__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(0 0 0 / 80%);
}

.c-popup__wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);

  @include media-breakpoint-up(sm) {
    max-width: calc(var(--space-2xl) * 5);
  }
}

.c-popup__container {
  --y: var(--space-xl);

  display: flex;
  flex-direction: column;
  padding: var(--space-m) var(--space-l);
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  transition: transform 0.6s;
  transform: translateY(var(--y));

  .show-popup & {
    --y: 0;
  }
}

.c-popup__close {
  margin-left: auto;
  font-weight: 700;
  cursor: pointer;
}
