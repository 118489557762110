%button {
  --icon-width: var(--space-s);
  --icon-height: var(--space-s);
  --icon-bg: var(--icon-arrow-45);

  display: inline-flex;
  align-items: center;
  padding: var(--space-xs) var(--space-s);
  color: var(--color-black);
  line-height: 1;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  background-color: var(--color-white);
  border: none;
  border-radius: var(--border-radius);
  appearance: none;

  &::before {
    @include arrow-icon(true);

    margin-right: var(--space-2xs);
  }

  .can-hover &:hover::before {
    @include arrow-icon-hover(true);
  }

  &.is-loading {
    pointer-events: none;

    &::before {
      background: none;
      border: 1px solid currentcolor;
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-radius: 100%;
      transition: none;
      /* stylelint-disable-next-line no-unknown-animations */
      animation: 0.5s infinite linear spin;
    }
  }
}

.o-button--reverse::before {
  transform: rotate(180deg);
}

.o-button {
  @extend %button;
}

.o-button--primary {
  background-color: var(--color-yellow);
}

.o-button--small {
  font-size: var(--step--1);
  font-weight: 500;
}
