.o-media {
  position: relative;
}

.o-media--bg {
  height: 0;
  padding-bottom: calc(var(--media-ratio) * 100%);

  .o-media__wrapper,
  .o-media__media {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.o-media__wrapper {
  overflow: hidden;
  border-radius: var(--media-border-radius, 0);
}

.o-media__media {
  --parallax: 0%;
  --top: calc(var(--parallax) * -1);
  --height: calc(100% + var(--parallax));

  position: relative;

  .is-smooth [data-scroll-progress] & {
    --parallax: 15%;

    will-change: transform;
    transform: translateY(calc(var(--progress, 0) * var(--parallax)));
  }

  .can-hover a & {
    --parallax: 0% !important;

    will-change: transform;
    transition: transform 0.6s;
  }

  .can-hover a:hover & {
    transform: scale(1.05);
  }
}

.o-media__video,
.o-media__image  {
  --ty: 10%;

  position: absolute;
  top: var(--top);
  width: 100%;
  height: var(--height);
  opacity: 1;
  transform: translateY(var(--ty, 0));
  transition: transform 0.8s cubic-bezier('easeOutQuart'), opacity 0.6s;
  transition-delay: calc(var(--index-delay, 0.15s) * (var(--index, 0) + var(--index-base, 1)) + var(--stagger-delay, 0.15s) * var(--stagger, 0));

  .o-media--fade & {
    transform: none;
    opacity: 0;
  }

  .is-inview &.lazyloaded {
    --ty: 0;

    opacity: 1;
  }
}

.o-media__video-wrapper .o-media__media {
  aspect-ratio: 16/9;
  .o-media__video {
    object-fit: cover;
    z-index: 1;
  }
}


.o-media__video.lazyloaded + .o-media__image {
  visibility: hidden;
}

.o-media__mask {
  position: absolute;
  z-index: 0;
  top: var(--top);
  width: 100%;
  height: var(--height);
  background-color: #000;
  opacity: var(--media-mask, 0);
}

.o-media__reveal {
  position: absolute;
  z-index: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .is-smooth [data-scroll-progress] & {
    transform: translateY(calc(var(--parallax) * var(--progress, 0) * -1));
  }

  &::before {
    // same as .c-artwork-preview__placeholder::before
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--media-reveal, var(--color-light-gray));
    transition: transform 0.8s cubic-bezier('easeOutQuart');
    transition-delay: calc(var(--index-delay, 0.15s) * (var(--index, 0) + var(--index-base, 1)) + var(--stagger-delay, 0.15s) * var(--stagger, 0));
    transform-origin: 0 0;
    content: '';
  }

  .is-inview .lazyloaded ~ &::before {
    transform: scaleY(0);
  }

  .o-media--fade & {
    display: none;
  }
}
