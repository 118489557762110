.c-page-header-media {
  --min-height: 70vh;
  --media-mask: 0.3;
  --media-reveal: var(--color-mid-blue-gray);

  position: relative;
  display: flex;
  overflow: hidden;
  min-height: var(--min-height);
  color: var(--color-white);

  @include media-breakpoint-up(sm) {
    --min-height: 100vh;
  }

  &::after {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    content: '';
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.4s 1.5s;
  }

  &.is-outro::after {
    opacity: 1;
  }

  .o-media {
    position: static;
  }
}

.c-page-header-media__media {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.c-page-header-media__content {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.c-page-header-media__title {
  padding: 1em 0;
  font-size: var(--step-8);
  text-align: center;

  .c-page-header-media--gl & {
    --index-base: 4;
  }
}

.c-page-header-media--gl {
  --min-height: 100vh;

  .o-media {
    --media-reveal: #000;

    display: none;
  }
}

.c-page-header-media--gl .o-gl.is-disabled ~ .o-media {
  display: block;
}

.c-page-header-media__nav {
  position: absolute;
  z-index: 3;
  bottom: var(--container-padding);
  display: flex;
  align-items: center;
  text-decoration: none;
  pointer-events: none;

  .o-pill {
    background: rgba(#fff, 0.3);
    pointer-events: auto;

    .is-transitioning & {
      pointer-events: none;
    }

    &::before {
      filter: invert(1);
    }
  }
}

.c-page-header-media__nav--prev {
  left: var(--container-padding);

  .o-pill {
    transform: scaleX(-1);
  }
}

.c-page-header-media__nav--next {
  right: var(--container-padding);

  .o-pill {
    order: 2;
  }
}

.c-page-header-media__nav-title {
  padding: 0 0.5em;
  font-size: var(--step--1);
  opacity: 0;
  transition-delay: 0.4s, 0.01s;
  transition-duration: 0.01s, 0.4s;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier('easeOutQuint');
  transform: translate(var(--x, 0.5em));

  .is-transitioning & {
    pointer-events: none !important;
  }

  .c-page-header-media__nav--next & {
    --x: -0.5em;
  }

  .can-hover a:hover & {
    --x: 0;

    pointer-events: auto;
    opacity: 1;
    transition-delay: 0.2s;
    transition-duration: 0.6s;
  }
}
