:root {
  --dvh: 1dvh;
  --body-font-family: #{$font-sans-serif};
  --body-bg: var(--color-mid-blue-gray);
  --column-width: calc((100vw - var(--container-padding) * 2 - var(--gutter-x) * 11) / 12);
  --column-width-gutter: calc(var(--column-width) + var(--gutter-x));
  --column-width-2-gutter: calc(var(--column-width) + var(--gutter-x) * 2);
  --site-menu-button-size: calc(var(--space-l) * 0.9);
  --site-menu-header-padding: var(--container-padding);
  --border-radius: var(--space-3xs);
  --space-section: var(--space-l);
  --space-section-medium: var(--space-l-2xl);
  --space-section-large: var(--space-2xl-3xl);
  --heading-line-height: calc(1.08em + 4px);
  --icon-arrow: url(#{svg('arrow', 'stroke: #000; stroke-width: 0.3px;')});
  --icon-arrow-45: url(#{svg('arrow-45', 'stroke: #000; stroke-width: 0.3px;')});

  // calcs
  --site-header-height: calc(var(--site-menu-button-size) + var(--site-menu-header-padding) * 2);

  @include media-breakpoint-up(sm) {
    --site-menu-button-size: var(--space-l);
  }
}
