.c-form-contact {

    .title-form {
      font-size: 72px;
      margin-bottom: 20px;
    }
  
    .subtitle-f {
      font-size: 24px;
      line-height: 125%;
    }
  
    @media screen and (max-width: 768px) {
      .title-form {
        font-size: 30px;
      }
  
      .subtitle-f {
        font-size: 20px;
      }
      
    }
}