.c-brands {
  + * {
    --flow: var(--space-section-large);
  }
}

.c-brands__title {
  --measure: 20ch;

  text-wrap: balance;
  margin-bottom: var(--space-xl);
  font-size: var(--step-5);

  @include media-breakpoint-up(sm) {
    font-size: var(--step-6);
  }

  .tpl-landing & {
    font-size: var(--step-5);
  }
}

.c-brands__item {
  --column-span: 6;

  position: relative;
  padding-bottom: 75%;
  background-color: #fff;
  border-radius: var(--border-radius);

  @include media-breakpoint-up(sm) {
    --column-span: 4;
  }

  @include media-breakpoint-up(md) {
    --column-span: 3;
  }
}

.c-brands__image {
  position: absolute;
  inset: 20%;
  background-size: contain;
}
