.o-prose {
  --flow: 1em;

  @extend %flow;

  color: inherit;

  ol, ul {
    @extend %flow;

    padding-left: 1.1em;

    li {
      --flow: 0.5em;
    }
  }

  h6 + h1,
  h6 + h2,
  h6 + h3,
  h6 + h4,
  h6 + h5 {
    --flow: var(--space-s);
  }

  // a:not([class]) {
  //   color: var(--blue);
  // }
}
