.group-images {
    .first-image {
        padding-bottom: 30px;

        .o-media__media {
            aspect-ratio: 4/3;
        }
    }

    .grid-images {
        display: flex;
        gap: 30px;

        .second-image {
            flex: 1;

            .o-media__media {
                aspect-ratio: 4/3;
            }
        }
    }

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        margin-inline: calc(-1* var(--container-padding));
    
        .first-image {
          
    
          .o-media {
            justify-self: center;
          
            .o-media__media {
              min-height: 80vh;
            }
          }
        }  
        
        .grid-images {
          display: block;
    
            .second-image {
                padding-bottom: 30px;
    
                .o-media {
                    justify-self: center;
            
                    .o-media__media {
                        min-height: 80vh;
                    }
                }
            }
        }
    }
}

.padding-images-section {
    padding: 30px 0;
}