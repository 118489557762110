.c-list-wrapper {
  + * {
    --flow: var(--space-section-large);
  }
}

.c-list-wrapper__title {
  margin-bottom: var(--space-l);
  color: var(--color-white);
  font-size: var(--step-8);

  .c-list-wrapper--small-title & {
    color: var(--color-black);
    font-size: var(--step-6);
  }
}
